<template>
  <div class="py-4 container-fluid">
    <VeeForm
      ref="formRef"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <div class="row">
        <div class="col-lg-6">
          <h4>{{$t('txt.10')}}</h4>
          <p>{{$t('txt.11')}}</p>
        </div>
        <div
          class="text-right col-lg-6 d-flex flex-column justify-content-center"
        >
          <!--<sweet-button
            :propsData="sweetDataSave"
            @btn-click="onSubmit"
          ></sweet-button>-->
          <material-button
            type="submit"
            class="mt-2 mb-0 btn bg-gradient-info ms-lg-auto me-lg-0 me-auto mt-lg-0"
            variant="gradient"
            color="dark"
            full-width
            >{{$t("btn.15")}}</material-button
          >
        </div>
      </div>
      <div class="mt-4 row">
        <!-- 교재 이미지 -->
        <div class="col-lg-4">
          <div class="card mt-4" data-animation="true">
            <div class="card-header p-0 position-relative mt-n4 mx-5 z-index-2">
              <input id="upload-image" type="file" hidden @change="getFileName($event.target.files)"/>
              <a class="d-block blur-shadow-image">
                <label for="upload-image" style="cursor: pointer;">
                  <img
                    id="preview"
                    :src="previewImage || require('@/assets/img/no_image.png')"
                    class="img-fluid shadow border-radius-lg"
                  />
                </label>
              </a>
            </div>
            <div class="card-body text-center"></div>
          </div>
        </div>
        <div class="mt-4 col-lg-8 mt-lg-0">
          <!-- 교재 정보 -->
          <div class="card">
            <div class="card-body">
              <h5 class="font-weight-bolder">{{$t('label.35')}}</h5>
              <div class="row mt-4">
                <div class="col-4 col-sm-4">
                  <Field v-slot="{ field, errors }" name="barcode">
                    <material-input
                      id="barcode"
                      variant="dynamic"
                      :label="$t('label.28')"
                      v-bind="field"
                      :error="errors.length > 0"
                      :disabled="!!$route.query.barcode"
                    />
                    <ErrorMessage name="barcode" class="text-danger" />
                  </Field>
                </div>
                <div class="col-4 col-sm-4">
                  <Field v-slot="{ field, errors }" name="title">
                    <material-input
                      id="title"
                      variant="dynamic"
                      :label="$t('label.14')"
                      v-bind="field"
                      :error="errors.length > 0"
                    />
                    <ErrorMessage name="title" class="text-danger" />
                  </Field>
                </div>
                <div class="col-4 col-sm-4">
                  <Field v-slot="{ field, errors }" name="publisher">
                    <material-input
                      id="publisher"
                      variant="dynamic"
                      :label="$t('label.29')"
                      v-bind="field"
                      :error="errors.length > 0"
                    />
                    <ErrorMessage name="publisher" class="text-danger" />
                  </Field>
                </div>              
              </div>
              <div class="row mt-4">
                <div class="mt-3 col-4 col-sm-4 mt-sm-0">
                  <Field v-slot="{ field }" name="category_idx">
                    <CustomSelect
                      :label="$t('label.30')"
                      :options="categoryOptions"
                      :model-value="field.value"
                      placeholder="Select a category"
                      @update:model-value="field.onInput"
                      @blur="field.onBlur"
                    />
                    <ErrorMessage name="category_idx" class="text-danger" />
                  </Field>
                </div>
                <div class="mt-3 col-4 col-sm-4 mt-sm-0">
                  <Field v-slot="{ field }" name="grade">
                    <CustomSelect
                      :label="$t('label.31')"
                      :options="gradeOptions"
                      :model-value="field.value"
                      placeholder="Select a grade"
                      @update:model-value="field.onInput"
                      @blur="field.onBlur"
                    />
                    <ErrorMessage name="grade" class="text-danger" />
                  </Field>
                </div>
                <div class="mt-3 col-4 col-sm-4 mt-sm-0">
                  <Field v-slot="{ field }" name="theme">
                    <CustomSelect
                      :label="$t('label.37')"
                      :options="themeOptions"
                      :model-value="field.value"
                      placeholder="Select Theme"
                      @update:model-value="field.onInput"
                      @blur="field.onBlur"
                    />
                    <ErrorMessage name="theme" class="text-danger" />
                  </Field>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <label class="mt-4 ms-0">Description</label>
                  <p class="text-xs form-text text-muted ms-1 d-inline">
                    (optional)
                  </p>
                  <div>
                    <QuillEditor v-model="textbooksData.description" :config="editorConfig" />
                  </div>
                  <!--<div v-html="textbooksData.description"></div>-->
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 row">
        <div class="col-sm-4">
          <!-- 공동 저자 -->
          <div class="card" style="min-height: 250px;">
            <div class="card-body">
              <h5 class="font-weight-bolder mb-4">{{$t('label.36')}}</h5>
              <ul v-if="co_authors.length > 0" class="text-muted ps-4 mb-0 float-start">
                <li v-for="author in co_authors" :key="author.id">
                  <span class="text-sm">{{ author.name }}</span>
                </li>
              </ul>
              <p v-else style="text-align: center">
                {{$t('txt.17')}}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-4 col-sm-8 mt-sm-0">
          <!-- 판매/대여 가격 -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <h5 class="font-weight-bolder mb-4">{{$t('label.34')}}</h5>
                <div class="col-2">{{$t('label.40')}}</div>
                <div class="col-2">
                  <Field v-slot="{ field }" name="product_type">
                    <CustomSelect
                      :options="productTypeOptions"
                      :model-value="field.value"
                      placeholder="Select a product type"
                      @update:model-value="field.onInput"
                      @blur="field.onBlur"
                      v-tooltip="'제품의 유형에 따라 판매/대여 가격이 달라집니다.'"
                    />
                    <ErrorMessage name="product_type" class="text-danger" />
                  </Field>
                </div>
                <div class="col-2">
                  <button type="button" class="btn btn-primary mt-2" @click.prevent="openModal">가격표 보기</button>
                </div>
                <!-- 가격표 모달창 -->
                <Modal :is-visible="isModalVisible" @close="closeModal" title="가격표">
                  <ul>
                    <li v-for="(option, index) in productPricing" :key="index">
                      {{ option.product_type }} type / {{ option.product_description }} / {{ option.list_price }} ({{option.currency_code}}) / {{ option.store }}
                    </li>
                  </ul>
                </Modal>
              </div>
              <div class="row">
                <div class="col-12">
                  <MultipleSelect
                    id="tags-select"
                    v-model="selectedTags"
                    label="Tags"
                    :options="tagOptions"
                    placeholder="Select tags"
                    :allow-search="true"
                    :multiple="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VeeForm>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */
//import SweetButton from "@/components/SweetButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import QuillEditor from "@/components/QuillEditor.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import MultipleSelect from "@/components/MultipleSelect.vue";
//import Swal from "sweetalert2";
import { Field, Form as VeeForm, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Modal from "@/components/Modal.vue";

export default {
  name: "EditProduct",
  components: { 
    MaterialInput, MaterialButton, CustomSelect, MultipleSelect, QuillEditor,
    Field, VeeForm, ErrorMessage, Modal
    //SweetButton, Swal, 
  },
  data() {
    return {
      textbooksData: {
        barcode: "",
        title: "",
        publisher: "",
        category_idx: null,
        grade: "",
        description: "",
        cover_image: "",
        theme: "",
        product_type: "",
      },
      previewImage: "", // 이미지를 표시할 경로
      editorConfig: {
        htmlView: true,
        minHeight: "300px",
        toolbarOptions: [
          ["bold", "italic"],
          [{ header: [1, 2, 3, false] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link","image"],
        ],
      },
      schema: yup.object({
        barcode: yup
          .string().min(6, this.$t('alert.49', { obj: this.$t('label.28'), count: 6 }))
          .required(this.$t('alert.71', { obj: this.$t('label.28') })),
        title: yup
          .string().required(this.$t('alert.71', { obj: this.$t('label.14') })),
        publisher: yup
          .string().required(this.$t('alert.71', { obj: this.$t('label.29') })),
        category_idx: yup
          .number().required(this.$t('alert.71', { obj: this.$t('label.30') })),
        grade: yup
          .string().required(this.$t('alert.71', { obj: this.$t('label.31') })),
        theme: yup
          .string().required(this.$t('alert.71', { obj: this.$t('label.37') })),        
      }),
      categoryOptions: [],
      themeOptions: [],
      gradeOptions: [
        { label: "Beginner", value: "beginner" },
        { label: "Intermediate", value: "intermediate" },
        { label: "Advanced", value: "advanced" },
      ],
      currencyOptions: [],
      isModalVisible: false,
      productTypeOptions: [
        { label: "A type", value: "A" },
        { label: "B type", value: "B" },
        { label: "C type", value: "C" },
        { label: "D type", value: "D" },
        { label: "E type", value: "E" },
        { label: "F type", value: "F" },
        { label: "G type", value: "G" }
      ],
      productPricing: [],
      tagOptions: [
        { label: "TOPIK", value: "TOPIK" },
        { label: "회화", value: "회화" },
        { label: "문법", value: "문법" },
        { label: "어휘", value: "어휘" },
        { label: "취업", value: "취업" },
        { label: "유학", value: "유학" },
        { label: "여행", value: "여행" },
        { label: "비즈니스", value: "비즈니스" },
      ],
      selectedTags: [],
      co_authors: []
    };
  },
  computed:{
    userInfo() {
      return this.$store.state.auth.user_info || {}; // 기본값 처리
    }
    //sweetDataSave() {
    //  var vm = this;
    //  return {
    //    class: "mt-2 mb-0 btn bg-gradient-info ms-lg-auto me-lg-0 me-auto mt-lg-0",
    //    btn_title: vm.$t("btn.15"),
    //    showSwalType: "warning-message-and-confirmation",
    //    title: vm.$t("alert.47"),
    //    text: "",
    //    beforeFn: function() {
    //      // 유효성 검증 실행
    //      const isValid = vm.$refs.formRef.validate();
    //      if (!isValid.valid) {
    //        // 검증 실패 시 전송 중단
    //        return false;
    //      }
    //      return true; // 검증 통과 시 전송 진행
    //    }
    //  };
    //}
  },
  watch: {},
  mounted() {
    this.getCategories();
    //this.getCurrencies();
    this.getThemes();
    this.getProductPricing();
    
    const barcode = this.$route.query.barcode;
    if(barcode != undefined){
      this.getTextbook(barcode);      
    }

    // userInfo가 null이 아닐 경우 즉시 실행
    if (this.$route.query.barcode) {
      this.getCoAuthor();
    }

    //console.log("Received barcode:", barcode);
  },
  methods: {
    async getThemes() {
      try {
        const res = await this.$axios({
          method: "get",
          url: "/common/themes",
          loadingBar: true,
        });

        if (res.status === 200 && Array.isArray(res.data)) {
          this.themeOptions = res.data;
        } else {
          console.error("Unexpected response format:", res.data);
        }
      } catch (error) {
        console.error("Error :", error);
      }
    },
    async getCategories() {
      try {
        const res = await this.$axios({
          method: "get",
          url: "/common/categories",
          loadingBar: true,
        });

        if (res.status === 200 && Array.isArray(res.data)) {
          // name을 label로 변경하여 categoryOptions 업데이트
          this.categoryOptions = res.data.map(item => ({
            label: item.name, // name을 label로 변경
            value: item.idx, // 서버 데이터에서 value로 사용할 속성 매핑
          }));

          //console.log(this.categoryOptions);
        } else {
          console.error("Unexpected response format:", res.data);
        }
      } catch (error) {
        console.error("Error :", error);
      }
    },
    /*async getCurrencies() {
      try {
        const res = await this.$axios({
          method: "get",
          url: "/common/currencies",
          loadingBar: true,
        });

        if (res.status === 200 && Array.isArray(res.data)) {
          // code을 label로 변경하여 currencyOptions 업데이트
          this.currencyOptions = res.data.map(item => ({
            label: item.code, // code을 label로 변경
            value: item.idx, // 서버 데이터에서 value로 사용할 속성 매핑
          }));
        } else {
          console.error("Unexpected response format:", res.data);
        }
      } catch (error) {
        console.error("Error :", error);
      }
    },*/
    async getProductPricing() {
      try {
        const res = await this.$axios({
          method: "get",
          url: "/textbook/productPricing",
          loadingBar: true,
        });

        if (res.status === 200 && Array.isArray(res.data)) {
          // name을 label로 변경하여 currencyOptions 업데이트
          this.productPricing = res.data;
        } else {
          console.error("Unexpected response format:", res.data);
        }
      } catch (error) {
        console.error("Error :", error);
      }
    },
    async getCoAuthor() {
      try {
        const res = await this.$axios({
          method: "get",
          url: "/textbook/editor/co-author",
          params: { 
            barcode: this.$route.query.barcode, 
            editor_idx: this.userInfo.idx 
          },
          loadingBar: true,
        });

        this.co_authors = res.data;
      } catch (error) {
        console.error("Error :", error);
      }
    },
    async getTextbook(barcode) {
      try {
        const res = await this.$axios({
          method: "get",
          url: `/textbook/textbooks/${barcode}`,
          loadingBar: true,
        });
        
        if (res.status === 200) {
          const data = res.data;
          //console.log(res.data);
          this.textbooksData = {
            barcode     : data.barcode,
            title       : data.title,
            publisher   : data.publisher,
            category_idx: data.category_idx,
            grade       : data.grade,
            description : data.description,
            cover_image : data.cover_image,
            theme       : data.theme,
            product_type: data.product_type
          };
          this.selectedTags = this.$stringToArr(data.tags);
          // 이미지 미리보기 경로 설정
          this.previewImage = data.cover_image ? `${data.cover_image}` : ""; // cover_image가 없으면 기본값 설정

          // vee-validate의 initialValues 설정
          this.$refs.formRef.setValues(this.textbooksData);

        }
      } catch (error) {
        console.error("Error :", error);
      }
    },
    async onSubmit(values, { resetForm }) {
      
      //console.log(values);
      const requestFn = async () => {

        if(!this.$route.query.barcode){ // 신규
          return await this.$axios({
            method: "post",
            url: "/textbook/editor/textbooks",
            data: {
              category_idx: values.category_idx,
              barcode: values.barcode,
              title: values.title,
              publisher: values.publisher,
              grade: values.grade,
              description: this.textbooksData.description,
              tags: this.$arrToString(this.selectedTags),
              theme: values.theme,
              product_type: values.product_type,
            },
          });
        }else{ // 업데이트

          return await this.$axios({
            method: "patch",
            url: `/textbook/editor/textbooks/${this.$route.query.barcode}`,
            data: {
              category_idx: values.category_idx,
              barcode: values.barcode,
              title: values.title,
              publisher: values.publisher,
              grade: values.grade,
              description: this.textbooksData.description,
              tags: this.$arrToString(this.selectedTags),
              theme: values.theme,
              product_type: values.product_type,
            },
          });
        }
        
      };

      // SweetAlert 확인창 + 요청 실행
      let result = await this.$showConfirmationAndSubmit(requestFn, resetForm, {'title': this.$t('alert.47')});
      
      if (result === "success") {
        const imageInput = document.getElementById("upload-image");
        const files = imageInput.files;

        if (files && files[0]) {
          // 이미지 파일이 있는 경우 업로드
          const formData = new FormData();
          formData.append("cover_image", files[0]);

          try {
            // 이미지 파일 업로드
            await this.$axios({
              method: "post",
              url: `/textbook/editor/textbooks/${values.barcode}/upload-cover`,
              data: formData, // FormData 객체 전송
            });            
          } catch (error) {
            console.error("Error uploading image:", error);
            // 이미지 업로드 실패 시 사용자에게 알림
            this.$notifyVue({
              title: this.$t('txt.12'),
              text: this.$t('txt.13'),
              type: "error",
            });
          }
        }

        // 데이터 저장 후 페이지 이동
        this.$router.push({ path: "textbooks" });
      }
      
    },
    notify(){
      // notify
      this.$notifyVue({
        title: '제목입니다',
        text: '내용입니다',
        type: 'info'
      })
    },
    async getFileName(files) {
      this.fileName = files[0]
      await this.base64(this.fileName)
    },
    base64(file) {
      // 비동기적으로 동작하기 위하여 promise를 return 해준다.
      return new Promise(resolve => {
        // 업로드된 파일을 읽기 위한 FileReader() 객체 생성
        let a = new FileReader()
        // 읽기 동작이 성공적으로 완료됐을 때 발생
        a.onload = e => {
          resolve(e.target.result)
          // 썸네일을 보여주고자 하는 <img>에 id값을 가져와 src에 결과값을 넣어준다.
          const previewImage = document.getElementById('preview')
          previewImage.src = e.target.result
        }
        // file 데이터를 base64로 인코딩한 문자열. 이 문자열을 브라우저가 인식하여 원래 데이터로 만들어준다.
        a.readAsDataURL(file)
      })
    },

    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }

  },
};

</script>
  