<template>
  <div class="upload-container">
    <label class="upload-box" @dragover.prevent="dragOver" @drop.prevent="dropFile">
      <input type="file" :accept="accept" @change="handleFileUpload" class="hidden-input" />
      <div class="upload-content">
        <i class="material-icons upload-icon">cloud_upload</i>
        <p v-if="fileUrl">File Uploaded: <span class="file-name">{{ fileName }}</span></p>
        <p v-else>Drag & Drop your file here or <span class="browse-text">Browse</span></p>
      </div>
    </label>

    <!-- 파일 미리보기 -->
    <div v-if="fileUrl" class="file-preview">
      <p><strong>Uploaded File:</strong> <a :href="fileUrl" target="_blank">{{ fileName }}</a></p>
      
      <!-- 오디오 파일일 경우 -->
      <audio v-if="isAudioFile" :src="fileUrl" controls class="audio-player"></audio>

      <!-- 오디오가 아닌 경우 다운로드 링크 -->
      <p v-else>
        <a :href="fileUrl" target="_blank" download class="download-link">Download File</a>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineModel, getCurrentInstance } from "vue";

const fileUrl = defineModel("fileUrl");   // 부모에서 `v-model:fileUrl`로 전달받음
const fileName = defineModel("fileName"); // 부모에서 `v-model:fileName`으로 전달받음

const props = defineProps({
  uploadUrl: { type: String, required: true },
  uploadPath: { type: String, default: "uploads/" },
  accept: { type: String, default: "" },
});

// Vue 인스턴스 가져오기 (`this.$axios` 사용 가능)
const { proxy } = getCurrentInstance();

// 업로드된 파일이 오디오인지 확인
const isAudioFile = computed(() => {
  return fileUrl.value && fileName.value?.toLowerCase().match(/\.(mp3|wav|ogg)$/);
});

const handleFileUpload = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  fileName.value = file.name;

  const formData = new FormData();
  formData.append("uploadPath", props.uploadPath);
  formData.append("files", file);
  
  try {
    const response = await proxy.$axios({
      method: "post",
      url: props.uploadUrl,
      data: formData,
      loadingBar: true, // 로딩바 활성화
    });

    if (response.data.success) {
      fileUrl.value = response.data.uploaded_files[0]; // 업로드된 파일 URL 저장
    } else {
      alert("File upload failed");
    }
  } catch (error) {
    console.error("Upload error:", error);
    alert("File upload failed");
  }
};

// Drag & Drop 기능
const dragOver = (event) => {
  event.preventDefault();
};

const dropFile = (event) => {
  event.preventDefault();
  const files = event.dataTransfer.files;
  if (files.length > 0) {
    handleFileUpload({ target: { files } });
  }
};
</script>

<style scoped>
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.upload-box {
  width: 100%;
  max-width: 400px;
  height: 100px;
  border: 2px dashed #12b8cd;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  background-color: #f9f9f9;
}

.upload-box:hover {
  background-color: #e3f6fc;
}

.upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-icon {
  font-size: 40px;
  color: #12b8cd;
}

.hidden-input {
  display: none;
}

.browse-text {
  color: #12b8cd;
  font-weight: bold;
}

.file-preview {
  margin-top: 10px;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.file-name {
  font-weight: bold;
  color: #12b8cd;
}

.audio-player {
  margin-top: 10px;
  width: 100%;
}

.download-link {
  color: #007bff;
  font-weight: bold;
  text-decoration: underline;
}
</style>
