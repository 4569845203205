<!--
부모컴포넌트에서 사용예시 :

<template>
  <div>
    <QuillEditor v-model="editorContent" :config="editorConfig" />
    <div>
      <h3>에디터 내용</h3>
      <div v-html="editorContent"></div>
    </div>
  </div>
</template>

<script>
import QuillEditor from "@/components/QuillEditor.vue";

export default {
  components: { QuillEditor },
  data() {
    return {
      editorContent: '<p>초기 값입니다.</p>', // 초기 데이터
      editorConfig: {
        htmlView: true
      },
    };
  },
};
</script>
//-->
<template>
  <div>
    <!-- Quill 에디터 -->
    <div ref="editorContainer" :style="`min-height: ${config.minHeight};color:black`"></div>

    <!-- HTML 및 Delta 보기/수정 모달 -->
    <teleport to="body">
      <div v-if="showModal" class="modal-overlay">
        <div class="modal-container">
          <!-- 닫기 버튼 -->
          <button @click="closeModal" class="modal-close-btn">
            <span class="material-icons">close</span>
          </button>

          <!-- 탭 -->
          <div class="modal-tabs">
            <button
              :class="{ active: activeTab === 'html' }"
              @click="activeTab = 'html'"
            >
              HTML 수정
            </button>
            <button
              :class="{ active: activeTab === 'delta' }"
              @click="activeTab = 'delta'"
            >
              Delta 보기
            </button>
          </div>

          <!-- HTML 수정 -->
          <textarea
            v-if="activeTab === 'html'"
            v-model="htmlContent"
            rows="15"
            style="width: 100%; margin-top: 20px; font-family: monospace; white-space: pre;"
          ></textarea>

          <!-- Delta 보기 -->
          <textarea
            v-if="activeTab === 'delta'"
            v-model="deltaString"
            rows="15"
            style="width: 100%; margin-top: 20px; font-family: monospace; white-space: pre;"
          ></textarea>

          <!-- 반영 버튼 -->
          <material-button
            v-if="activeTab === 'html'"
            color="primary"
            variant="outline"
            class="mt-2"
            @click="applyChanges"
          >
            <i class="material-icons position-relative text-md pe-2">save</i>
            반영하기
          </material-button>
        </div>
      </div>
    </teleport>

    <!-- HTML 보기 버튼 -->
    <material-button
      color="secondary"
      variant="outline"
      class="mb-0 ms-auto mt-2"
      type="button"
      v-if="config.htmlView"
      @click="openModal"
      v-tooltip="$t('txt.16')"
    >
      <i class="material-icons position-relative text-md pe-2">code</i>
      HTML/Delta 보기
    </material-button>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import Quill from "quill";
import QuillTableBetter from "quill-table-better";
import BlotFormatter from "quill-blot-formatter";
import "quill/dist/quill.snow.css";
import "quill-table-better/dist/quill-table-better.css";

// quill-table-better 등록
Quill.register({ "modules/table-better": QuillTableBetter }, true);

// quill-blot-formatter 등록
Quill.register("modules/blotFormatter", BlotFormatter);

export default {
  name: "QuillEditor",
  components: { MaterialButton },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    config: {
      type: Object,
      default: () => ({
        minHeight: "300px",
        htmlView: false,
        toolbarOptions: [],
      }),
    },
  },
emits: ['update:modelValue'],
  data() {
    return {
      editor: null,
      htmlContent: "", // HTML 내용
      deltaContent: null, // Delta 객체
      deltaString: "", // Delta JSON 문자열
      showModal: false, // 모달 표시 상태
      activeTab: "html", // 활성화된 탭 (html or delta)
    };
  },
  computed: {
    resolvedConfig() {
      // 부모로부터 props로 받은 config와 기본값 병합
      return {
        minHeight: this.config?.minHeight || "300px",
        htmlView: this.config?.htmlView || false,
        toolbarOptions: this.config?.toolbarOptions?.length
          ? this.config.toolbarOptions
          : [
              // 텍스트 포맷팅
              ['bold', 'italic', 'underline', 'strike'], // 굵게, 기울임, 밑줄, 취소선
              [{ script: 'sub' }, { script: 'super' }], // 첨자, 위첨자

              // 헤더 및 텍스트 크기
              [{ header: [1, 2, 3, 4, 5, 6, false] }], // 헤더 크기
              [{ size: ['small', false, 'large', 'huge'] }], // 텍스트 크기
              [{ font: [] }], // 글꼴 선택

              // 색상
              [{ color: [] }, { background: [] }], // 텍스트 색상 및 배경색

              // 정렬 및 방향
              [{ align: [] }], // 텍스트 정렬 (좌, 우, 가운데, 양쪽 정렬)
              [{ direction: 'rtl' }], // 오른쪽에서 왼쪽 정렬 (RTL)

              // 리스트 및 들여쓰기
              [{ list: 'ordered' }, { list: 'bullet' }], // 순서가 있는/없는 리스트
              [{ indent: '-1' }, { indent: '+1' }], // 내어쓰기, 들여쓰기

              // 인용 및 코드 블록
              ['blockquote', 'code-block'], // 인용 블록 및 코드 블록

              // 삽입 기능
              ['link', 'image', 'video'], // 링크, 이미지, 비디오 삽입

              // 테이블 기능
              ['table-better'], // 테이블 삽입 (quill-table-better)

              // 초기화
              ['clean'], // 포맷 초기화
            ],
      };
    },
  },
  watch: {
    modelValue(newValue) {
      if (this.editor && this.editor.root.innerHTML !== newValue) {
        this.editor.root.innerHTML = newValue; // 부모의 데이터로 에디터 초기화
        this.htmlContent = newValue; // HTML 수정 영역도 업데이트
        this.deltaString = JSON.stringify(this.editor.getContents().ops, null, 2); // Delta 업데이트
      }
    },
  },
  mounted() {
    this.initializeQuill();
  },
  methods: {
    initializeQuill(){

      if (!this.$refs.editorContainer) {
        console.error("Editor container not found");
        return;
      }

      // Vue DOM 렌더링 완료 후 초기화
      this.$nextTick(() => {
        const options = {
          theme: 'snow',
          modules: {
            toolbar: {
              container: this.resolvedConfig.toolbarOptions,
              handlers: {
                image: this.imageHandler, // 이미지 핸들러 추가
              },
            },
            table: false,
            'table-better': {
              language: 'en_US',
              menus: ['column', 'row', 'merge', 'table', 'cell', 'wrap', 'delete'],
              toolbarTable: true,
            },
            blotFormatter: {}, // blotFormatter 활성화
          },
        };

        // Quill 에디터 초기화
        this.editor = new Quill(this.$refs.editorContainer, options);

        // Quill 에디터 내용 변경 이벤트 처리
        this.editor.on("text-change", () => {
          const html = this.editor.root.innerHTML;
          const delta = this.editor.getContents();
          this.htmlContent = html;
          this.deltaContent = delta;
          this.deltaString = JSON.stringify(delta.ops, null, 2); // Delta를 JSON 문자열로 저장

          this.$emit("update:modelValue", html);
        });

        // 초기 값 설정
        this.editor.root.innerHTML = this.modelValue;
        this.deltaContent = this.editor.getContents(); // Delta 초기화
        this.deltaString = JSON.stringify(this.deltaContent.ops, null, 2);
      });
      
    },
    openModal() {
      // 현재 HTML 및 Delta 데이터 설정
      this.htmlContent = this.editor.root.innerHTML;
      this.deltaString = this.deltaContent
        ? JSON.stringify(this.deltaContent.ops, null, 2)
        : ""; // Delta가 null인지 확인
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    applyChanges() {
      if (this.activeTab === "html") {
        // HTML 수정 내용 반영
        this.editor.root.innerHTML = this.htmlContent;
      } else if (this.activeTab === "delta") {
        try {
          // Delta 수정 내용 반영
          const parsedDelta = JSON.parse(this.deltaString);
          this.editor.setContents({ ops: parsedDelta });
        } catch (error) {
          alert("Delta 데이터가 올바르지 않습니다!");
          console.error("Delta JSON 파싱 오류:", error);
        }
      }

      // Quill 에디터의 변경 사항을 부모로 전달
      const html = this.editor.root.innerHTML;
      this.$emit("update:modelValue", html);
      this.showModal = false;
    },
    // 대안적인 이미지 핸들러 메서드
    imageHandler() {
      const url = prompt('이미지 URL을 입력하세요:');
      if (url) {
        // 에디터에 HTML 직접 삽입
        const currentContent = this.editor.root.innerHTML;
        const imgTag = `<img src="${url}" alt="이미지">`;
        this.editor.root.innerHTML = currentContent + imgTag;
        
        // 변경 사항 전파
        const html = this.editor.root.innerHTML;
        this.$emit("update:modelValue", html);
      }
    },
  },
};
</script>

<style scoped>
@import "~quill/dist/quill.snow.css";
@import "~quill-table-better/dist/quill-table-better.css";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  position: relative;
}

.modal-tabs button {
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border: none;
  background: #f0f0f0;
}

.modal-tabs button.active {
  background: #007bff;
  color: white;
}

textarea {
  font-family: monospace;
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: auto;
}
</style>
