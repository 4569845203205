<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
              <h6 class="text-white text-capitalize ps-3">{{ $t('label.41') }} - {{ bookTitle }}</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="px-4">
              <!-- 드래그 앤 드롭 영역 -->
              <div 
                class="upload-area"
                @dragover.prevent="onDragOver"
                @dragleave.prevent="onDragLeave"
                @drop.prevent="onDrop"
                :class="{ 'active-drop': isDragging }"
              >
                <div v-if="!isDragging" class="text-center p-5">
                  <i class="material-icons text-7xl text-primary">cloud_upload</i>
                  <h5 class="mt-3">{{ $t('txt.04') }}</h5>
                  <p>{{ $t('txt.05') }}</p>
                  <input 
                    type="file" 
                    ref="fileInput" 
                    multiple 
                    accept="image/*" 
                    class="d-none" 
                    @change="onFileSelected"
                  />
                  <button 
                    class="btn bg-gradient-primary" 
                    @click="$refs.fileInput.click()"
                  >
                    UPLOAD
                  </button>
                </div>
                <div v-else class="text-center p-5">
                  <i class="material-icons text-7xl text-success">file_download</i>
                  <h5 class="mt-3">drop files here</h5>
                </div>
              </div>

              <!-- 업로드 진행 상태 -->
              <div v-if="uploadProgress > 0 && uploadProgress < 100" class="mt-4">
                <div class="progress">
                  <div 
                    class="progress-bar bg-gradient-success" 
                    role="progressbar" 
                    :style="`width: ${uploadProgress}%`" 
                    :aria-valuenow="uploadProgress" 
                    aria-valuemin="0" 
                    aria-valuemax="100"
                  >
                    {{ uploadProgress }}%
                  </div>
                </div>
              </div>

              <!-- 이미지 갤러리 -->
              <div class="mt-4">
                <h6>LIST OF IMAGES</h6>
                <div v-if="images.length === 0" class="text-center py-5">
                  <p>NO IMAGES</p>
                </div>
                <div v-else class="row">
                  <div 
                    v-for="(image, index) in images" 
                    :key="index" 
                    class="col-md-3 col-sm-4 col-6 mb-4"
                  >
                    <div class="card image-card">
                      <div class="image-container">
                        <img :src="image.url" class="card-img-top" :alt="image.filename">
                        <div class="image-overlay">
                          <button 
                            class="btn btn-sm btn-danger" 
                            @click="deleteImage(image.filename)"
                            title="삭제"
                          >
                            DELETE
                          </button>
                          <button 
                            class="btn btn-sm btn-info ms-2" 
                            @click="copyImageUrl(image.url)"
                            title="URL 복사"
                          >
                            COPY
                          </button>
                        </div>
                      </div>
                      <div class="card-footer p-2">
                        <p class="text-sm mb-0 text-truncate" :title="image.filename">{{ image.filename }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageStorage",
  data() {
    return {
      barcode: "",
      bookTitle: "",
      images: [],
      isDragging: false,
      uploadProgress: 0
    };
  },
  mounted() {
    this.barcode = this.$route.query.barcode;
    if (this.barcode) {
      this.fetchImages();
      this.fetchBookInfo();
    } else {
      this.$router.push({ name: "Textbooks" });
    }
  },
  methods: {
    async fetchBookInfo() {
      try {
        const res = await this.$axios({
          method: "get",
          url: `/textbook/textbooks/${this.barcode}`,
          loadingBar: true,
        });
        
        if (res.status === 200) {
          this.bookTitle = res.data.title;
        }
      } catch (error) {
        console.error("Error fetching book info:", error);
        this.$notifyVue({ type: "error", title: this.$t('alert.50') });
      }
    },
    async fetchImages() {
      try {
        const res = await this.$axios({
          method: "get",
          url: `/textbook/imageStorage/${this.barcode}`,
          loadingBar: true,
        });
        
        if (res.status === 200) {
          // 서버에서 받아온 이미지 데이터를 가공하여 images 배열에 저장
          // 각 이미지 객체에 대해 url 속성이 없으면 API 경로를 기반으로 url을 생성
          this.images = res.data.map(img => ({
            ...img, // 기존 이미지 객체의 모든 속성을 복사
            // url이 있으면 그대로 사용, 없으면 API 경로로 생성
            url: img.url || `${this.$CONF.API_URL}/textbook/textbookContents/images/${this.barcode}/${img.filename}`
          }));
        }
      } catch (error) {
        console.error("Error fetching images:", error);
        this.$notifyVue({ type: "error", title: this.$t('alert.50') });
      }
    },
    onDragOver() {
      this.isDragging = true;
    },
    onDragLeave() {
      this.isDragging = false;
    },
    onDrop(e) {
      this.isDragging = false;
      const files = e.dataTransfer.files;
      if (files.length) {
        this.uploadFiles(files);
      }
    },
    onFileSelected(e) {
      const files = e.target.files;
      if (files.length) {
        this.uploadFiles(files);
      }
    },
    async uploadFiles(files) {
      const imageFiles = Array.from(files).filter(file => 
        file.type.startsWith('image/')
      );
      
      if (imageFiles.length === 0) {
        this.$notifyVue({ type: "error", title: this.$t('alert.50') });
        return;
      }

      this.uploadProgress = 1;
      
      try {
        if (imageFiles.length === 1) {
          // 단일 파일 업로드
          await this.uploadSingleFile(imageFiles[0]);
        } else {
          // 다중 파일 업로드
          await this.uploadMultipleFiles(imageFiles);
        }
        
        this.$notifyVue({ type: "success", title: this.$t('alert.74') });
        this.fetchImages();
      } catch (error) {
        console.error("Upload error:", error);
        this.$notifyVue({ type: "error", title: this.$t('alert.50') });
      } finally {
        this.uploadProgress = 0;
        if (this.$refs.fileInput) {
          this.$refs.fileInput.value = '';
        }
      }
    },
    async uploadSingleFile(file) {
      const formData = new FormData();
      formData.append('file', file);
      
      const res = await this.$axios({
        method: "post",
        url: `/textbook/imageStorage/${this.barcode}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        }
      });
      
      return res;
    },
    async uploadMultipleFiles(files) {
      const formData = new FormData();
      files.forEach(file => {
        formData.append('files', file);
      });
      
      const res = await this.$axios({
        method: "post",
        url: `/textbook/imageStorage/${this.barcode}/images/multiple`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        }
      });
      
      return res;
    },
    async deleteImage(filename) {
      if (!confirm(this.$t('alert.06'))) {
        return;
      }
      
      try {
        const res = await this.$axios({
          method: "delete",
          url: `/textbook/imageStorage/${this.barcode}/images/${filename}`,
          loadingBar: true,
        });
        
        if (res.status === 200 || res.status === 204) {
          this.$notifyVue({ type: "success", title: this.$t('alert.09') });
          this.fetchImages();
        }
      } catch (error) {
        console.error("Error deleting image:", error);
        this.$notifyVue({ type: "error", title: this.$t('alert.50') });
      }
    },
    copyImageUrl(url) {
      navigator.clipboard.writeText(url)
        .then(() => {
          this.$notifyVue({ type: "success", title: this.$t('alert.73') });
        })
        .catch(err => {
          console.error('Failed to copy URL: ', err);
          this.$notifyVue({ type: "error", title: this.$t('alert.50') });
        });
    }
  }
};
</script>

<style scoped>
.upload-area {
  border: 2px dashed #ccc;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.active-drop {
  border-color: #4CAF50;
  background-color: rgba(76, 175, 80, 0.1);
}

.image-card {
  transition: all 0.3s ease;
  height: 100%;
}

.image-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.image-container {
  position: relative;
  overflow: hidden;
  height: 150px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .image-overlay {
  opacity: 1;
}
</style>
