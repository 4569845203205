<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <h6 class="mb-0">{{ itemsConfig.title }}</h6>
    </div>
    <div class="p-3 card-body border-radius-lg" ref="sortableContainer">
      <div
        v-if="events.length === 0"
        class="text-center"
        style="min-height: 50px;"
      >
        No data
      </div>
      <div
        v-else
        v-for="({ idx, sort, title }, index) in events"
        :key="idx"
        class="d-flex sortable-item"
        :class="[{ 'mt-4': index !== 0 }, { 'selected-item': selectedItem === idx }]"
      >
        <!-- 아이콘 -->
        <div
          class="icon icon-shape shadow text-center"
          :class="`bg-gradient-dark`"
        >
          <i class="material-icons opacity-10 pt-1">auto_stories</i>
        </div>
        <!-- 내용 -->
        <div class="ms-3 flex-grow-1">
          <div class="numbers">
            <h6 class="mb-1 text-sm text-dark">
              {{ itemsConfig.label }} {{ sort }}
            </h6>
            <span class="text-sm" v-html="title" />
          </div>
        </div>
        <!-- 버튼 -->
        <div class="button-container">
          <a class="mb-0 btn btn-link pe-3 ps-0" @click="fnPreView(idx)" style="color:blueviolet" v-show="itemsConfig.label == 'Section'">preview</a>
          <a class="mb-0 btn btn-link pe-3 ps-0" @click="fnView(idx)" style="color:green">view</a>
          <a class="mb-0 btn btn-link pe-3 ps-0" @click="fnDelete(idx)">delete</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";

export default {
  name: "ItemsList",
  props: {
    itemsConfig: {
      type: Object,
      default: () => ({}),
    },
    events: {
      type: Array,
      default: () => [],
    },
    chapterNumber: {
      type: Number,
      default: 0,
    },
  },
  emits: { "update:events": null, view: null, 'delete': null },
  data() {
    return {
      selectedItem: null, // 현재 선택된 아이템 저장
    };
  },
  mounted() {
    Sortable.create(this.$refs.sortableContainer, {
      animation: 300,
      ghostClass: "sortable-ghost",
      onStart: (event) => {
        console.log("Drag started:", event);
      },
      onEnd: this.onDragEnd,
    });
  },
  methods: {
    onDragEnd(event) {
      const updatedEvents = [...this.events];
      const movedItem = updatedEvents[event.oldIndex];
      updatedEvents.splice(event.oldIndex, 1);
      updatedEvents.splice(event.newIndex, 0, movedItem);
      updatedEvents.forEach((item, index) => {
        item.sort = index + 1;
      });
      this.$emit("update:events", updatedEvents);
    },
    fnPreView(idx) {
      const section = this.events.find(item => item.idx === idx);
      if (!section) {
        console.warn("해당 섹션을 찾을 수 없습니다.");
        return;
      }

      const sectionNumber = idx;

      const clientId    = `section_${this.$route.query.barcode}_${this.chapterNumber}_${sectionNumber}`;
      const emulatorUrl = `http://192.168.0.122:8080/?client_id=${clientId}`;  // 새창에서 연결될 주소
      const previewUrl  = `${this.$CONF.API_URL}/textbook/preview/${clientId}`;

      // 1. 새 창으로 에뮬레이터 페이지 열기 (태블릿 사이즈의 새 윈도우창)
      window.open(emulatorUrl, '_blank', 'width=768,height=1024,menubar=no,toolbar=no,location=no,status=no');

      // 2. 데이터 전송 (WebSocket 연결은 에뮬레이터가 하고 있어야 함)
      setTimeout(() => {
        fetch(previewUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(section),
        })
          .then(res => res.json())
          .then(data => {
            console.log("Preview 전송 완료:", data);
          })
          .catch(err => {
            console.error("Preview 전송 실패:", err);
          });
      }, 500);  // 새 창 열리고 WebSocket 연결될 시간 약간 기다림
    },
    fnView(idx) {
      this.selectedItem = idx; // 선택된 아이템 업데이트
      this.$emit("view", idx);
    },
    fnDelete(idx) {
      this.$emit("delete", idx);
      if (this.selectedItem === idx) {
        this.selectedItem = null; // 삭제 시 선택 해제
      }
    },
  },
};
</script>

<style scoped>
.sortable-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 55px; /* 기존보다 조금 높게 설정 */
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.ms-3 {
  flex-grow: 1;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

.sortable-ghost {
  opacity: 0.4;
}

/* 선택된 아이템 배경색 및 여백 추가 */
.selected-item {
  background-color: #e3f2fd;
  border-radius: 12px; /* 더 둥글게 */
  padding: 10px 12px; /* 좌우 여백도 추가 */
  margin: 5px 0; /* 위아래 여백 추가 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 선택 시 더 강조 */
}
</style>