<template>
  <div>
    <!-- Quiz Type 선택 -->
    <CustomSelect
      id="questionTypeSelect"
      label="Question Type"
      :options="questionTypeOptions"
      v-model="sectionData.content.questionType"
      @update:model-value="onQuestionTypeChange"
      style="width: 30%;"
    />
    <hr style="color: blue; height: 3px;" />

    <!-- 객관식 (Objective) -->
    <div v-if="sectionData.content.questionType === 'objective'" class="quiz-section">
      <h6 class="mt-3">Objective Question</h6>
      <material-input
        id="objectiveQuestionText"
        variant="dynamic"
        label="Question Text"
        v-model="sectionData.content.questionText"
        class="input-item"
      />
      <div v-for="(option, index) in sectionData.content.options" :key="index" class="quiz-item gameCard mt-4">
        <div class="row mt-3">
          <div class="col-8">
            <material-input
              :id="'objectiveOptionText-' + index"
              variant="dynamic"
              label="Option Text"
              v-model="option.option_text"
              class="input-item"
            />
          </div>
          <div class="col-4">
            <image-upload
              :default-image="option.option_image || require('@/assets/img/no_image.png')"
              :unique-id="'objective-option-image-' + index"
              v-model="option.option_image"
            />
          </div>
        </div>
        <button @click.prevent="removeOption(index)" class="btn-remove mt-2">Remove Option</button>
      </div>
      <button @click.prevent="addOption" class="btn btn-primary mt-2">Add Option</button>

      <!-- Correct Answers -->
      <div class="mt-3">
        <label>Correct Answers</label>
        <MultipleSelect
          id="correctAnswersSelect"
          v-model="sectionData.content.correctAnswer"
          :options="correctAnswerOptions"
          placeholder="Correct Answers"
          :allow-search="true"
          :multiple="true"
        />
      </div>
    </div>

    <!-- 주관식 (Subjective) -->
    <div v-if="sectionData.content.questionType === 'subjective'" class="quiz-section">
      <h6 class="mt-3">Subjective Question</h6>
      <CustomSelect
        id="subjectiveSubtypeSelect"
        label="Question Subtype"
        :options="subjectiveSubtypeOptions"
        v-model="sectionData.content.questionSubtype"
        style="width: 30%;"
        @update:model-value="onSubjectiveSubtypeChange"
      />
      <material-input
        id="subjectiveQuestionText"
        variant="dynamic"
        label="Question Text"
        v-model="sectionData.content.questionText"
        class="input-item mt-3"
      />

      <!-- Short Answer 옵션 -->
      <div v-if="sectionData.content.questionSubtype === 'short'" class="mt-4">
        <div v-for="(option, index) in sectionData.content.options" :key="index" class="quiz-item gameCard mt-3">
          <div class="row mt-3">
            <div class="col-8">
              <material-input
                :id="'shortOptionTextInput-' + index"
                variant="dynamic"
                label="Option Text"
                v-model="option.option_text"
                class="input-item mt-3"
              />
              <material-input
                :id="'shortHintInput-' + index"
                variant="dynamic"
                label="Hint"
                v-model="option.hint"
                class="input-item mt-3"
              />
              <material-input
                :id="'shortAnswerInput-' + index"
                variant="dynamic"
                label="Answer"
                v-model="option.answer"
                class="input-item mt-3"
              />
            </div>
            <div class="col-4">
              <image-upload
                :default-image="option.option_image ? option.option_image : require('@/assets/img/no_image.png')"
                :unique-id="'subjective-short-image-' + index"
                v-model="option.option_image"
              />
            </div>
          </div>
          <button @click.prevent="removeSubjectiveOption(index)" class="btn-remove mt-2">Remove Option</button>
        </div>

        <!-- 옵션 추가 버튼 -->
        <button @click.prevent="addSubjectiveOption" class="btn btn-primary mt-2">Add Option</button>
      </div>
      <!-- Long Answer 옵션 -->
      <div v-else class="mt-4">
        <div class="row mt-3">
          <div class="col-12">
            <label>Upload Question Image</label>
            <image-upload
              :default-image="sectionData.content.questionImage || require('@/assets/img/no_image.png')"
              unique-id="long-question-image"
              v-model="sectionData.content.questionImage"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Fill in the Blank Question -->
    <div v-if="sectionData.content.questionType === 'fill_in_the_blank'" class="quiz-section">
      <h6 class="mt-3">Fill in the Blank Question</h6>

      <material-input
        id="fillInTheBlankQuestionText"
        variant="dynamic"
        label="Question Text"
        v-model="sectionData.content.questionText"
        class="input-item mt-3"
      />

      <!-- 옵션 반복 출력 -->
      <div v-for="(option, index) in sectionData.content.options" :key="index" class="quiz-item gameCard mt-3">
        <div class="row mt-3">
          <div class="col-8">
            <h6>Option {{ index + 1 }}</h6>
            
            <!-- ✅ option_text 입력 추가 -->
            <material-input
              :id="'fillBlankOptionText-' + index"
              variant="dynamic"
              label="Option Text"
              v-model="option.option_text"
              class="input-item"
            />
            <!-- 정답 입력 (쉼표로 구분) -->
            <div class="row mt-2">
              <div class="col-12">
                <label>Answers (comma-separated)</label>
                <textarea
                  :id="'fillBlankAnswer-' + index"
                  class="form-control"
                  v-model="option.answerString"
                  @input="updateAnswerArray(index)"
                  placeholder="처음,이름이 뭐예요,어느 나라,중국 사람"
                ></textarea>
              </div>
            </div>        
          </div>
          <div class="col-4">
            <image-upload
              :default-image="option.option_image ? option.option_image : require('@/assets/img/no_image.png')"
              :unique-id="'fillInTheBlank-image-' + index"
              v-model="option.option_image"
            />
          </div>
        </div>

        <!-- 옵션 제거 버튼 -->
        <button @click.prevent="removeFillBlankOption(index)" class="btn-remove mt-2">Remove Option</button>
      </div>

      <!-- 새 옵션 추가 버튼 -->
      <button @click.prevent="addFillBlankOption" class="btn btn-primary mt-3">Add Option</button>      
    </div>

    <!-- 오디오 파일 업로드 -->
    <div class="mt-3">
      <label><b>Audio File Upload</b></label>
      <file-upload
        accept=".mp3"
        upload-url="/common/fileUpload"
        :upload-path="`textbookAudio/${$route.query.barcode}/`"
        v-model:fileName="sectionData.content.audio"
        v-model:fileUrl="sectionData.content.audioUrl"
      />
    </div>

  </div>
</template>

<script setup>
import { defineModel, computed } from 'vue';
import MaterialInput from "@/components/MaterialInput.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import FileUpload from "@/components/FileUpload.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import MultipleSelect from "@/components/MultipleSelect.vue";

// `defineModel()`을 사용하여 v-model을 자동으로 연결
const sectionData = defineModel({ type: Object, required: true });

const questionTypeOptions = [
  { value: "objective", label: "Objective" },
  { value: "subjective", label: "Subjective" },
  { value: "fill_in_the_blank", label: "Fill in the blank" },
];

const subjectiveSubtypeOptions = [
  { value: "short", label: "Short Answer" },
  { value: "long", label: "Long Answer" },
];

const correctAnswerOptions = computed(() =>
  sectionData.value.content.options.map((option, index) => ({
    value: `${index + 1}`, // 고유 ID 사용
    label: `Option ${index + 1}`
  }))
);

// 문제 유형 변경 시 데이터 초기화
const onQuestionTypeChange = (newValue) => {
  sectionData.value.content = {
    questionType: newValue,
    questionText: "",
    ...(newValue === "objective"
      ? { options: [], correctAnswer: [] }
      : newValue === "subjective"
      ? { 
          questionSubtype: "short", 
          options: [{ option_text: "", hint: "", option_image: null, answer: "" }] // 🔥 여기서 `null` 설정
        }
      : newValue === "fill_in_the_blank"
      ? { options: [{ option_text: "", option_image: null, answer: [], answerString: "" }], audio: "" }
      : {}),
  };
};

// 객관식 옵션 추가
const addOption = () => {
  sectionData.value.content.options.push({ option_text: "", option_image: "" });
};

// 객관식 옵션 제거
const removeOption = (index) => {
  sectionData.value.content.options.splice(index, 1);
};

// 주관식 옵션 추가
const addSubjectiveOption = () => {
  if (!sectionData.value.content.options) {
    sectionData.value.content.options = [];
  }
  sectionData.value.content.options.push({ option_text: "", hint: "", option_image: null, answer: "" });
};

const removeSubjectiveOption = (index) => {
  sectionData.value.content.options.splice(index, 1);
};


const onSubjectiveSubtypeChange = (newValue) => {
  if (newValue === "short") {
    sectionData.value.content.options = [{ option_text: "", hint: "", option_image: null, answer: "" }];
  } else if (newValue === "long") {
    delete sectionData.value.content.options;
    sectionData.value.content.questionImage = "";
  }
};

// 옵션 추가
const addFillBlankOption = () => {
  sectionData.value.content.options.push({ option_text: "", option_image: null, answer: [], answerString: "" });
};

// 옵션 삭제
const removeFillBlankOption = (index) => {
  sectionData.value.content.options.splice(index, 1);
};

// 쉼표로 구분된 문자열을 배열로 변환
const updateAnswerArray = (optionIndex) => {
  const option = sectionData.value.content.options[optionIndex];
  option.answer = option.answerString.split(",").map(item => item.trim()).filter(item => item !== "");
};

// 파일 업로드 URL 설정
//const uploadUrl = "/common/fileUpload";

</script>

<style scoped>
.quiz-section {
  margin-top: 20px;
}
.quiz-item {
  margin-bottom: 15px;
}
.gameCard {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid gray;
}

.btn-remove {
  background: none;
  border: none;
  color: #dc3545;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
}

.btn-remove:hover {
  text-decoration: underline;
}

textarea.form-control {
  width: 100%;
  height: 80px;
  resize: vertical;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

</style>