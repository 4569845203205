<template>
  <div>
    <!-- Text 입력 (JoditEditor 사용) -->
    <label for="content">{{ $t('label.39') }}</label>
    <!-- QuillEditor 으로 변경하려면 주석제거하고 JoditEditor를 주석
    <QuillEditor 
        id="content"
        v-model="sectionData.content.text" 
        :config="{ htmlView: true, minHeight: '500px' }" 
    />-->
    <JoditEditor 
      id="content"
      v-model="sectionData.content.text" 
      :config="editorConfig"
    />
    <!-- Guide Book -->
    <div class="mt-3">
      <h6>Guide Book</h6>
      <div v-for="(guide, index) in sectionData.guideBook" :key="index" class="mb-2">
        <material-input
          :id="'guideTitle' + index"
          variant="dynamic"
          label="Guide Title"
          v-model="guide.title"
          style="width: 50%;"
        /><br/>
        <QuillEditor 
          :id="'guideContent' + index"
          v-model="guide.content" 
          :config="{ htmlView: false, minHeight: '100px' }" 
        />
      </div>
      <button @click.prevent="addGuideBook" class="btn btn-primary mt-2">Add Guide Book</button>
    </div>

    <!-- 오디오 파일 업로드 -->
    <div class="mt-3">
      <label><b>Audio File Upload</b></label>
      <file-upload
        accept=".mp3"
        upload-url="/common/fileUpload"
        :upload-path="`textbookAudio/${$route.query.barcode}/`"
        v-model:fileName="sectionData.content.audio"
        v-model:fileUrl="sectionData.content.audioUrl"
      />
    </div>
  </div>
</template>

<script setup>
import { 
  defineModel, 
  //onMounted 
} from 'vue';
import QuillEditor from "@/components/QuillEditor.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import JoditEditor from '@/components/JoditEditor.vue';
import FileUpload from "@/components/FileUpload.vue";

// `defineModel()` 사용
const sectionData = defineModel({ type: Object, required: true });

// JoditEditor 설정
const editorConfig = {
  minHeight: 500,
};

// Guide Book 추가 함수
const addGuideBook = () => {
  if (!sectionData.value.guideBook) {
    sectionData.value.guideBook = [];
  }
  sectionData.value.guideBook.push({ title: "", content: "" });
};
</script>
  