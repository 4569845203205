<template>
    <div class="container-fluid">
      <div
        class="page-header min-height-300 border-radius-xl mt-4"
        style="
          background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80');
        "
      >
        <span class="mask bg-gradient-primary opacity-6"></span>
      </div>
      <div class="card card-body mx-3 mx-md-4 mt-n6">
        <div class="row gx-4">
          <div
            class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3"
          >
            <div class="nav-wrapper position-relative end-0">
              <ul class="p-1 nav nav-pills nav-fill" role="tablist">
                <li class="nav-item">
                  <a
                    class="px-0 py-1 mb-0 nav-link active"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="true"
                  >
                    <i class="material-icons text-lg position-relative">book</i>
                    <span class="ms-1">{{$t('navi.01_01')}}</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="px-0 py-1 mb-0 nav-link"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="false"
                  >
                    <i class="material-icons text-lg position-relative">insert_chart</i>
                    <span class="ms-1">{{$t('navi.01_02')}}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <section class="py-3">
        <div class="row mb-4 mb-md-0">
          <div class="col-md-8 me-auto my-auto text-left">
            <h5>{{$t('label.27')}}</h5>
            <p>{{$t('txt.09')}}</p>
          </div>
          <div class="col-lg-4 col-md-12 my-auto text-end">
            <button
              type="button"
              class="btn bg-gradient-success mb-0 mt-0 mt-md-n9 mt-lg-0"
              @click="addNew"
            >
              <i class="material-icons text-white position-relative text-md pe-2">add</i>
              {{$t('btn.17')}}
            </button>
          </div>
        </div>
        <div class="mt-2 row mt-lg-4">

          <div v-for="(item, index) in textbooks" :key="index" class="mt-4 mb-3 col-lg-4 col-md-6">
            <complex-project-card
              :image    = item.cover_image_url
              :title    = item.title
              :publisher= item.publisher
              :description=item.description
              :date-time=item.created_at
              :members="[]"
              :dropdown="[
                {
                  label: $t('txt.10'),
                  route: '/copyright/editTextbook?barcode='+item.barcode,
                },
                {
                  label: $t('label.38'),
                  route: '/copyright/editTextbookContents?barcode='+item.barcode,
                },
                {
                  label: $t('label.41'),
                  route: '/copyright/imageStorage?barcode='+item.barcode,
                }
              ]"
            />
          </div>

        </div>
      </section>
    </div>
  </template>
  
  <script>
  import team2 from "@/assets/img/team-2.jpg";
  import team3 from "@/assets/img/team-3.jpg";
  import slackLogo from "@/assets/img/small-logos/logo-slack.svg";
  import invisionLogo from "@/assets/img/small-logos/logo-invision.svg";
  import spotifyLogo from "@/assets/img/small-logos/logo-spotify.svg";
  import xdLogo from "@/assets/img/small-logos/logo-xd.svg";
  import asanaLogo from "@/assets/img/small-logos/logo-asana.svg";
  import atlassianLogo from "@/assets/img/small-logos/logo-atlassian.svg";
  
  import ComplexProjectCard from "@/views/pages/profile/components/ComplexProjectCard.vue";
  import setNavPills from "@/assets/js/nav-pills.js";
  
  export default {
    name: "ProfileTeam",
    components: {
      ComplexProjectCard,
    },
    data() {
      return {
        textbooks: [],
        showMenu: false,
        team2,
        team3,
        slackLogo,
        invisionLogo,
        spotifyLogo,
        xdLogo,
        asanaLogo,
        atlassianLogo,
      };
    },
    computed: {
      userInfo() {
        return this.$store.state.auth.user_info || {}; // 기본값 처리
      }
    },
    watch: {},
    mounted() {
      this.$store.state.isAbsolute = true;
      setNavPills();
      
      // userInfo가 null이 아닐 경우 즉시 실행
      if (this.userInfo && this.userInfo.name) {
        this.getTextbooks();
      }
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
    },
    methods: {
      async getTextbooks() {
        try {
          const res = await this.$axios({
            method: "get",
            url: "/textbook/editor/textbooks",
            params: { editor_idx: this.userInfo.idx, limit: 100, page: 1 },
            loadingBar: true,
          });
          
          if (res.status === 200) {
            this.textbooks = res.data;
            //console.log(res.data);
          }
        } catch (error) {
          console.error("Error :", error);
        }
      },
      addNew(){
        this.$router.push({ name: "Edit Textbook" }); 
      }
    },
  };
  </script>
  