<template>
  <div class="image-upload-component">
    <!-- 이미지 미리보기 -->
    <div class="preview-wrapper">
      <img
        :src="preview || defaultImageSrc"
        class="img-fluid shadow border-radius-lg"
        alt="Image Preview"
      />
    </div>

    <!-- URL 입력 필드 -->
    <div class="url-input-wrapper mt-3">
      <div class="input-group input-group-outline">
        <input
          type="text"
          class="form-control"
          placeholder="Image URL"
          v-model="imageUrl"
          @input="updatePreview"
        />
        <button 
          class="btn btn-sm btn-primary" 
          @click.prevent="applyUrl"
        >
          <i class="material-icons">check</i>
        </button>
        <button 
          v-if="preview && preview !== defaultImageSrc" 
          class="btn btn-sm btn-danger" 
          @click.prevent="clearUrl"
        >
          <i class="material-icons">delete</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageUpload",
  props: {
    defaultImage: {
      type: String,
      default: require("@/assets/img/no_image.png"),
    },
    modelValue: {
      type: String,
      default: "",
    },
    uniqueId: {
      type: String,
      required: true,
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      preview: null,
      imageUrl: "",
      internalUrl: "" // 내부적으로 전체 URL 저장
    };
  },
  computed: {
    defaultImageSrc() {
      return this.defaultImage;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (newValue && typeof newValue === 'string') {
          if (this.isValidUrl(newValue)) {
            // URL인 경우 그대로 사용
            this.preview = newValue;
            this.imageUrl = newValue;
            this.internalUrl = newValue;
          } else {
            // 파일명인 경우, 이전 URL 경로 유지하고 파일명만 변경
            if (this.internalUrl) {
              const urlParts = this.internalUrl.split('/');
              urlParts[urlParts.length - 1] = newValue;
              const reconstructedUrl = urlParts.join('/');
              this.preview = reconstructedUrl;
              this.imageUrl = reconstructedUrl;
            } else {
              this.preview = this.defaultImageSrc;
              this.imageUrl = "";
            }
          }
        } else {
          this.preview = this.defaultImageSrc;
          this.imageUrl = "";
          this.internalUrl = "";
        }
      },
    },
  },
  methods: {
    updatePreview() {
      // URL이 유효한지 확인 (간단한 검증)
      if (this.isValidUrl(this.imageUrl)) {
        this.preview = this.imageUrl;
        this.internalUrl = this.imageUrl;
      }
    },
    
    applyUrl() {
      if (!this.imageUrl) {
        this.$notifyVue({ type: "warning", title: this.$t('alert.75') });
        return;
      }
      
      if (!this.isValidUrl(this.imageUrl)) {
        this.$notifyVue({ type: "error", title: this.$t('alert.77') });
        return;
      }
      
      this.preview = this.imageUrl;
      this.internalUrl = this.imageUrl;
      
      // 파일명만 추출하여 부모에게 전달
      const fileName = this.imageUrl.split('/').pop();
      this.$emit("update:modelValue", fileName);
      
      this.$notifyVue({ type: "success", title: this.$t('alert.74') });
    },
    
    clearUrl() {
      this.preview = this.defaultImageSrc;
      this.imageUrl = "";
      this.internalUrl = "";
      this.$emit("update:modelValue", "");
    },
    
    isValidUrl(url) {
      if (!url) return false;
      
      try {
        new URL(url);
        return url.match(/\.(jpeg|jpg|gif|png|webp)$/i) !== null || 
               url.includes('/image') || 
               url.includes('/img');
      } catch (e) {
        return false;
      }
    }
  },
};
</script>

<style scoped>
.image-upload-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.preview-wrapper {
  position: relative;
  width: 200px;
  height: 200px;
  border: 1px dashed #ddd;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}
.preview-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.url-input-wrapper {
  width: 100%;
  max-width: 400px;
}

.form-control {
  height: 42px;
}
</style>
